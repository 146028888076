@import "https://unpkg.com/open-props";

:root {
    --accent-clr: #80c0ca;
    --sandy-accent-color: #f2e8e1;
    --main-font-clr-gray: #707070;

    --main-font: "ivypresto-display", serif;
    --cormorant-font: 'Cormorant Garamond', serif;
    --fun-font: 'IM Fell DW Pica', serif;
    --subtext-font: 'Kristi', cursive;
    --secondary-fun-font: 'IM Fell English SC', serif;
    --line_color: #555555 ;
    --back_color: white  ;
}

body {
    min-height: 100vh;
    font-weight: 300;
    border: none;
    overflow-x: hidden;
    margin: 0;
    display: table;
}

/* header styles */
header {
    box-shadow: rgba(0,0,0,.08) 0 3px 15px;
    width: 100vw;
    height: 170px;
    margin-bottom: 160px;
}

@media (max-width: 1557px) {
    header {
        width: 100vw;
    }
}

.navbar {
    max-width: 1800px;
    margin: auto;
}

.split-home {
    display: flex;
    justify-content: space-between;
}

.split {
    display: flex;
    justify-content: space-between;
}

.navbar-menu-items {
    margin: 60px 50px 0 0;
}

.is-active {
    color: var(--accent-clr);
}

.is-active hr {
    margin-top: 0px;
    height: .2px;
    border: none;
    display: block;
    background-color: var(--accent-clr);
}

.inactive {
    color: black;
}

.inactive hr {
    display: none;
}

.navbar-menu-items ul {
    display: flex;
    flex-direction: row;
}

.navbar-menu-items ul li {
    margin: 0 15px 0 15px; 
    list-style: none;
}

.navbar-menu-items ul li a {
    text-decoration: none;
    font-size: 20px;
    font-family: var(--cormorant-font);
    text-transform: uppercase;
}

.navbar-logo img {
    width: 400px;
    margin-top: -130px;
}

label #button,
label #cancel {
    font-size: 1.5rem;
    margin: 0px 40px 0 0;
    cursor: pointer;
    display: none;
}

#check {
    display: none;
}

@media (max-width: 1450px) {
    .navbar-logo img {
        margin-top: -120px;
        width: 400px;
    }

    .navbar-menu-items ul li a {
        font-size: 20px;
    }
}


@media (max-width: 1200px) {
    label #button {
        display: block;
    }

    nav ul li:nth-child(1) {
        margin-left: 50px;
    }

    .navbar-menu-items ul {
        position: fixed;
        background-color: #ffffffec;
        backdrop-filter: blur(20px);
        width: 100%;
        height: 102%;
        top: -17px;
        left: -110%;
        text-align: center;
        transition: all 750ms;
        z-index: 5000;
        flex-direction: column;
        backdrop-filter: blur(2px);
    }

    .is-active hr {
        display: none;
    }

    .navbar-menu-items ul li {
        display: block;
        top: 1px;
        margin-left: 45%;
        padding-top: 50px;
    }

    li a {
        font-size: 1.1rem;
    }

    #check:checked ~ ul {
        left: -50%;
    }

    #check:checked ~ label #button {
        display: none;
    }

    #check:checked ~ label #cancel {
        position: fixed;
        z-index: 5000;
        top: 61px;
        right: 35px;
        display: block;
        background-color: rgba(0, 0, 0, 0.047);
    }
}

@media (max-width: 950px) {
    .navbar-logo img {
        margin-top: -80px;
        width: 300px;
    }

    .navbar-menu-items ul li a {
        font-size: 20px;
    }

    header {
        height: 150px;
        margin-bottom: 160px;
    }
}

@media (max-width: 600px) {
    .navbar-logo img {
        margin-top: -30px;
        width: 200px;
    }

    .navbar-menu-items ul li a {
        font-size: 20px;
    }

    header {
        height: 140px;
        margin-bottom: 160px;
    }

    label #button,
    label #cancel {
        font-size: 1.5rem;
        margin: 0px -10px 0 0;
    }
}
/* home page styles */

.home-about-section {
    max-width: 1200px;
    margin: auto;
}



.home-about-section img {
    width: 400px;
    margin: -50px 0 0 70px;
    z-index: 0;
    position: relative;
    border-radius: 10px;
}

.home-about-paragraph p:nth-child(1) {
    max-width: 480px;
    font-family: var(--cormorant-font);
    font-size: 50px;
    color: var(--main-font-clr-gray);
    position: relative;
    text-align: center;
    margin-bottom: -35px;
    font-weight: 400;
}

.home-about-paragraph p:nth-child(1):before {
    content: "";
    display: block;
    width: 90px;
    height: 3px;
    background: #d6d5d5;
    left: 0;
    top: 50%;
    position: absolute;
}

.home-about-paragraph p:nth-child(2) {
    color: var(--accent-clr);
    font-size: 35px;
    font-style: italic;
}

.home-about-paragraph {
    width: 600px;
    font-family: var(--cormorant-font);
    font-weight: 100;
    font-size: 23px;
    margin-top: 55px;
    margin-right: 50px;
    text-align: justify;
    color: var(--main-font-clr-gray);
    position: relative;
}


.home-about-paragraph div {
    text-align: center;
}

.home-about-paragraph div button {
    margin-top: 20px;
    border: 2px solid var(--accent-clr);
    font-size: 25px;
    font-family: var(--cormorant-font);
    padding: 5px 20px 5px 20px;
    background-color: white;
    color: var(--main-font-clr-gray);
}

.home-about-paragraph div button:hover {
    background-color: var(--accent-clr);
    color: white;
    cursor: pointer;
}

.home-reference-section {
    background-color: var(--sandy-accent-color);
    width: 100vw;
    padding: 50px 0 50px 0;
    margin: 130px 0 130px 0;
}

.quote {
    max-width: 1000px;
    margin: auto;
}

.home-reference-section blockquote {
    color: var(--main-font-clr-gray);
    font-family: Georgia, serif;
    font-size: 1.2em;
    font-style: italic;
    line-height: 1.4;
    margin: 0;
    position: relative;
    text-shadow: 0 1px white;
    text-align: justify;
}

.home-reference-section blockquote p:first-child:before {
    content: '\201C';
    color: var(--accent-clr);
    font-size: 7em;
    font-weight: 700;
    opacity: .4;
    position: absolute;
    top: -.4em;
    left: -.3em;    
    text-shadow: none;
    z-index: 400;
}

.home-reference-section cite {
    color: gray;
    display: block;
    font-size: .9em; 
}
  
.home-reference-section cite span {
    color: #5e5e5e;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 1px white; 
}

.more-references {
    text-align: center;
    margin-top: 40px;
}

.more-references a {
    text-decoration: none;
    color: var(--main-font-clr-gray);
}

.more-references p {
    margin-top: 0px;
    text-transform: uppercase;
}

.more-references p:hover {
    cursor: pointer;
    color: var(--accent-clr);
}


.photo-section {
    margin: 100px 0 0 0;
}

#pics {
    display: flex;
    justify-content: center;
    margin: 100px 0px 50px 0;
    margin-top: 100px;
}

#pics div img {
    width: 400px;
    height: 600px;
    padding: 0 10px 0 10px;
    position: relative;
}

.home-photo-container {
    position: relative;
    text-align: center;
}

.overlay {
    background-color: #00000000;
    width: 400px;
    height: 600px;
    position: absolute;
    top: 49.8%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay:hover {
    background-color: #00000057;
    cursor: pointer;
}

#photo-text {
    color: rgb(240, 240, 240);
    font-weight: 400;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--cormorant-font);
    font-size: 40px;
}

#photo-subtext {
    color: rgb(240, 240, 240);
    font-weight: 200;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--fun-font);
    font-size: 20px;
    width: 350px;
    visibility: hidden;
}

.overlay:hover #photo-subtext {
    visibility: visible;
}

#home-verse {
    max-width: 1300px;
    margin: auto;
    text-align: center;
}

#home-verse img {
    width: 1000px;
    margin: 50px 0 -150px 0;
}

@media (max-width: 1270px) {
    .split-home {
        flex-direction: column;
        text-align: center;
    }
    
    .home-about-section img {
        width: 400px;
        margin: 0;
    }
    
    .home-about-paragraph p:nth-child(1) {
        max-width: 480px;
        font-size: 50px;
        margin-bottom: -35px;
        font-weight: 400;
    }
    
    .home-about-paragraph p:nth-child(1):before {
        width: 90px;
        height: 3px;
        left: 0;
        top: 50%;
    }
    
    .home-about-paragraph p:nth-child(2) {
        font-size: 35px;
    }
    
    .home-about-paragraph {
        width: 600px;
        font-weight: 100;
        font-size: 23px;
        margin: auto;
    }
    
    
    .home-about-paragraph div {
        text-align: center;
    }
    
    .home-about-paragraph div button {
        margin-top: 20px;
        border: 2px solid var(--accent-clr);
        font-size: 25px;
        padding: 5px 20px 5px 20px;
    }
    
    .home-reference-section {
        width: 100vw;
        padding: 50px 0 50px 0;
        margin: 130px 0 130px 0;
    }
    
    .quote {
        max-width: 850px;
        margin: auto;
    }
    
    .home-reference-section blockquote {
        font-size: 16.5px;
        line-height: 1.4;
        margin: 0;
    }
    
    .home-reference-section blockquote p:first-child:before {
        font-size: 102px;
    }
    
    .home-reference-section cite {
        font-size: 11.9px; 
    }
      
    .home-reference-section cite span {
        font-size: 1.1em;
    }
    
    .more-references {
        margin-top: 40px;
    }
    
    .more-references p {
        font-size: 13.6px;
    }
    
    .photo-section {
        margin: 100px 0 0 0;
    }
    
    #pics {
        display: flex;
        margin: 100px 0px 50px 0;
        margin-top: 100px;
    }
    
    #pics div img {
        width: 300px;
        height: 450px;
        padding: 0 10px 0 10px;
        position: relative;
    }
    
    .overlay {
        background-color: #00000000;
        width: 300px;
        height: 450px;
        position: absolute;
        top: 49.8%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    #photo-text {
        font-size: 30px;
    }
    
    #photo-subtext {
        font-size: 15px;
        width: 350px;
    }
    
    #home-verse {
        max-width: 1300px;
        margin: auto;
        text-align: center;
    }
    
    #home-verse img {
        width: 750px;
        margin: 50px 0 -150px 0;
    }
}

@media (max-width: 1025px) {
    .split-home {
        flex-direction: column;
        text-align: center;
    }
    
    .home-about-section img {
        width: 400px;
        margin: 0;
    }
    
    .home-about-paragraph p:nth-child(1) {
        max-width: 480px;
        font-size: 50px;
        margin-bottom: -35px;
        font-weight: 400;
    }
    
    .home-about-paragraph p:nth-child(1):before {
        width: 90px;
        height: 3px;
        left: 0;
        top: 50%;
    }
    
    .home-about-paragraph p:nth-child(2) {
        font-size: 35px;
    }
    
    .home-about-paragraph {
        width: 600px;
        font-weight: 100;
        font-size: 23px;
        margin: auto;
    }
    
    
    .home-about-paragraph div {
        text-align: center;
    }
    
    .home-about-paragraph div button {
        margin-top: 20px;
        border: 2px solid var(--accent-clr);
        font-size: 25px;
        padding: 5px 20px 5px 20px;
    }
    
    .home-reference-section {
        width: 100vw;
        padding: 50px 0 50px 0;
        margin: 130px 0 130px 0;
    }
    
    .quote {
        max-width: 650px;
        margin: auto;
    }
    
    .home-reference-section blockquote {
        font-size: 12.6px;
        line-height: 1.4;
        margin: 0;
    }
    
    .home-reference-section blockquote p:first-child:before {
        font-size: 78px;
    }
    
    .home-reference-section cite {
        font-size: 9.1px; 
    }
    
    .more-references {
        margin-top: 40px;
    }
    
    .more-references p {
        font-size: 10.4px;
    }
    
    .photo-section {
        margin: 50px 0 0 0;
    }
    
    #pics {
        display: flex;
        flex-direction: column;
        /* margin: 50px 0px 50px 0;
        margin-top: 50px; */
    }
    
    #pics div img {
        width: 400px;
        height: 600px;
        padding: 10px 0 10px 0;
        position: relative;
    }
    
    .overlay {
        background-color: #00000000;
        width: 400px;
        height: 600px;
        position: absolute;
        top: 49.8%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    #photo-text {
        font-size: 40px;
    }
    
    #photo-subtext {
        font-size: 20px;
        width: 350px;
    }
    
    #home-verse {
        max-width: 1300px;
        margin: auto;
        text-align: center;
    }
    
    #home-verse img {
        width: 750px;
        margin: 50px 0 -150px 0;
    }
}

@media (max-width: 725px) { 
    .home-about-section img {
        width: 300px;
        margin: -50px 0 0 0;
    }
    
    .home-about-paragraph p:nth-child(1) {
        max-width: 360px;
        font-size: 37.5px;
        margin-bottom: -35px;
        font-weight: 400;
    }
    
    .home-about-paragraph p:nth-child(1):before {
        width: 67.5px;
        height: 2.5px;
        left: 0;
        top: 50%;
    }
    
    .home-about-paragraph p:nth-child(2) {
        font-size: 26.25px;
    }
    
    .home-about-paragraph {
        width: 450px;
        font-weight: 100;
        font-size: 17.25px;
    }
    
    .home-about-paragraph div button {
        margin-top: 20px;
        border: 2px solid var(--accent-clr);
        font-size: 18.45px;
        padding: 5px 20px 5px 20px;
    }
    
    .home-reference-section {
        width: 100vw;
        padding: 50px 0 50px 0;
        margin: 130px 0 130px 0;
    }
    
    .quote {
        max-width: 450px;
        margin: auto;
    }
    
    .home-reference-section blockquote {
        font-size: 8.7px;
        line-height: 1.4;
        margin: 0;
    }
    
    .home-reference-section blockquote p:first-child:before {
        font-size: 54px;
    }
    
    .home-reference-section cite {
        font-size: 6.3px; 
    }
    
    .more-references {
        margin-top: 30px;
    }
    
    .more-references p {
        font-size: 7.2px;
    }
    
    .photo-section {
        margin: 50px 0 0 0;
    }
    
    #pics {
        display: flex;
        flex-direction: column;
    }
    
    #pics div img {
        width: 300px;
        height: 450px;
    }
    
    .overlay {
        background-color: #00000000;
        width: 300px;
        height: 450px;
        position: absolute;
        top: 49.8%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    #photo-text {
        font-size: 30px;
    }
    
    #photo-subtext {
        font-size: 15px;
    }
    
    #home-verse img {
        width: 562.5px;
        margin: 50px 0 -100px 0;
    }
}

@media (max-width: 575px) { 
    .home-about-section img {
        width: 200px;
        margin: -50px 0 0 0;
    }
    
    .home-about-paragraph p:nth-child(1) {
        max-width: 240px;
        font-size: 24.9px;
        margin-bottom: -35px;
        font-weight: 400;
    }
    
    .home-about-paragraph p:nth-child(1):before {
        width: 45px;
        height: 2.5px;
        left: 0;
        top: 50%;
    }
    
    .home-about-paragraph p:nth-child(2) {
        margin-top: 26px;
        font-size: 19px;
    }
    
    .home-about-paragraph {
        width: 300px;
        font-size: 11.5px;
    }
    
    .home-about-paragraph div button {
        margin-top: 20px;
        margin-bottom: -50px;
        border: 2px solid var(--accent-clr);
        font-size: 12.3px;
        padding: 5px 20px 5px 20px;
    }
    
    .home-reference-section {
        width: 100vw;
        padding: 50px 0 50px 0;
        margin: 130px 0 100px 0;
    }
    
    .quote {
        max-width: 320px;
        margin: auto;
    }
    
    #pics div img {
        width: 200px;
        height: 300px;
        padding: 10px 0 10px 0;
        position: relative;
    }
    
    .overlay {
        background-color: #00000000;
        width: 200px;
        height: 300px;
        position: absolute;
        top: 49.8%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    #photo-text {
        font-size: 20px;
    }
    
    #photo-subtext {
        font-size: 10px;
    }
    
    #home-verse {
        max-width: 1300px;
        margin: auto;
        text-align: center;
    }
    
    #home-verse img {
        width: 360px;
        margin: 0px 0 -75px 0;
    }
}

.thank-you {
    text-align: center;
}

.thank-you p {
    font-size: 25px;
    font-family: var(--cormorant-font);
    color: var(--main-font-clr-gray)
}

/* references page  */

.references-page h2 {
    text-align: center;
    font-size: 50px;
    font-family: var(--cormorant-font);
    color: var(--main-font-clr-gray);
    font-weight: 500;
    margin-top: -50px;
    margin-bottom: -50px;
}

.references-page h2 b {
    color: var(--accent-clr);
    font-weight: 400;
}

.all-references {
    padding: 50px 25px 50px 25px;
    margin: 130px 0 130px 0;
}

.all-references:nth-child(odd) {
    background-color: var(--sandy-accent-color);
    text-align: right;
}

.all-references blockquote {
    color: var(--main-font-clr-gray);
    font-family: Georgia, serif;
    font-size: 1.1em;
    font-style: italic;
    line-height: 1.5;
    margin: 0;
    position: relative;
    text-shadow: 0 1px white;
}

.all-references blockquote p:first-child:before {
    content: '\201C';
    color: var(--accent-clr);
    font-size: 7em;
    font-weight: 700;
    opacity: .4;
    position: absolute;
    top: -.4em;
    left: -.3em;    
    text-shadow: none;
    z-index: 400;
}

.all-references cite {
    color: gray;
    display: block;
    font-size: 1em; 
}
  
.all-references cite span {
    color: #5e5e5e;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 1px white; 
}

@media (max-width: 600px) {
    .references-page h2 {
        font-size: 40px;
        margin-top: -80px;
        margin-bottom: -100px;
    }

    .all-references blockquote {
        font-size: 1em;
    }
    
    .all-references blockquote p:first-child:before {
        content: '\201C';
        color: var(--accent-clr);
        font-size: 6.5em;
        font-weight: 700;
        opacity: .4;
        position: absolute;
        top: -.4em;
        left: -.3em;    
        text-shadow: none;
        z-index: 400;
    }
    
    .all-references cite {
        color: gray;
        display: block;
        font-size: .9em; 
    }
      
    .all-references cite span {
        color: #5e5e5e;
        font-size: 1.1em;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-shadow: 0 1px white; 
    }
}
/* media page */

#media-container {
    max-width: 1500px;
    margin: auto;
}

h3 {
    text-align: center;
    font-size: 50px;
    font-family: var(--cormorant-font);
    color: var(--main-font-clr-gray);
    font-weight: 500;
}

h3 b {
    color: var(--accent-clr);
    font-weight: 400;
}

.download-headshots-container {
    margin-bottom: 150px;
}

.headshot-split {
    display: flex;
    justify-content: center;
}

.headshot-split img {
    object-fit: cover;
    width: 400px;
    height: 400px;
    padding: 20px;
}

.headshot-1 {
    object-position: 0% 30%;
}

.headshot-2 {
    object-position: 0% 0%;
}

.headshot-3 {
    object-position: 45% 0%;
}

.download-container {
    position: relative;
}

.download-container:hover img {
    -webkit-filter: brightness(60%);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}

.download-container:hover {
    cursor: pointer;
} 

.download-container:hover .download-btn {
    bottom: 45%;
    right: 43.5%;
    color: var(--accent-clr);
    font-size: 50px;
}

.download-container img {
    display: block;
}

.download-btn {
    font-size: 35px;
    position: absolute;
    bottom: 25px;
    right: 30px;
    transition: .5s;
    color: var(--main-font-clr-gray);
}

.sharon-bio {
    margin-bottom: 100px;
    text-align: center;
}

.bio-box {
    width: 900px;
    font-family: var(--cormorant-font);
    font-size: 25px;
    color: var(--main-font-clr-gray);
    padding: 25px;
    margin: auto;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    border-right: 3px;
	border-left: 3px;
	border-style: solid;
    border-image: linear-gradient(to bottom, #ffffff, var(--accent-clr), #ffffff) 1 100%;
}

.copy-btn {
    font-size: 30px;
    color: var(--main-font-clr-gray);
}

.copy-btn:hover {
    color: var(--accent-clr);
    cursor: pointer;
}

.check-box {
    color: var(--accent-clr);
}

.popup {
    position: fixed;
    left: 50%;
    margin-left: -187.5px; 
    bottom: 0;
    width: 375px;
    background-color: #fff;
    color: var(--main-font-clr-gray);
    font-family: var(--cormorant-font);
    border-left: 10px solid var(--accent-clr);
    text-align: center;
    padding: 6px;
    border-radius: 5px;
    font-size: 25px;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .popup.active {
    transform: translateY(-100%);
  }

.media-split {
    display: flex;
    justify-content: center;
}

.media-split div {
    padding: 0 20px 80px 20px;
    width: 500px;
}

.media-split p {
    text-align: center;
    font-family: var(--cormorant-font);
    color: var(--main-font-clr-gray);
    font-size: 30px;
}

.yt-lite {
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
}

.yt-lite:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
    background-position: top;
    background-repeat: repeat-x;
    height: 60px;
    padding-bottom: 50px;
    width: 100%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.yt-lite:after {
    content: "";
    display: block;
    padding-bottom: var(--aspect-ratio);
}

.yt-lite > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.yt-lite > .lty-playbtn {
    width: 70px;
    height: 46px;
    background-color: #212121;
    z-index: 1;
    opacity: 0.8;
    border-radius: 14%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.yt-lite:hover > .lty-playbtn {
    background-color: red;
    opacity: 1;
    cursor: pointer;
}

.yt-lite > .lty-playbtn:before {
    content: "";
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    border-width: 11px 0 11px 19px;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.yt-lite.lyt-activated {
    cursor: unset;
}

.yt-lite.lyt-activated:before,
.yt-lite.lyt-activated > .lty-playbtn {
    opacity: 0;
    pointer-events: none;
}

.gen-his {
    position: relative;
    max-width: 1000px;
    margin: auto;
    margin-top: 150px;
    margin-bottom: -75px;
    z-index: 200;
}

.gen-his p {
    text-align: center;
    font-size: 35px;
    color: var(--main-font-clr-gray);
    font-family: var(--cormorant-font);
    
}

.gen-his a {
    text-decoration: none;
    color: var(--accent-clr);
}

.gen-his-logo {
    display: block;
    margin: auto;
    width: 70%;
}

@media (max-width: 1325px) {
    .headshot-split img {
        width: 300px;
        height: 300px;
        padding: 15px;
    }

    .download-headshots-container {
        margin-top: -75px;
        margin-bottom: 100px;
    }

    h3 {
        font-size: 40px;
    }

    .thank-you p {
        font-size: 20px;
    }

    .download-btn {
        font-size: 26.25px;
        bottom: 18.75px;
        right: 22.5px;
    }

    .download-container:hover .download-btn {
        font-size: 37.5px;
    }

    .sharon-bio {
        margin-bottom: 80px;
    }

    .bio-box {
        width: 700px;
        font-size: 20px;
    }

    .copy-btn {
        font-size: 25px;
    }

    .media-split div {
        padding: 0 20px 80px 20px;
        width: 400px;
    }

    .media-split p {
        font-size: 25px;
    }

    .yt-lite > .lty-playbtn {
        width: 56px;
        height: 36.8px;
    }
    
    .yt-lite > .lty-playbtn:before {
        border-width: 8.79px 0 8.79px 15.19px;
    }

    .gen-his {
        margin-top: 100px;
        margin-bottom: -50px;
    }
    
    .gen-his p {
        font-size: 26px;
    }
}

@media (max-width: 1025px) {
    .headshot-split img {
        width: 200px;
        height: 200px;
        padding: 10px;
    }

    h3 {
        font-size: 30px;
    }

    .thank-you p {
        font-size: 15px;
    }

    .download-btn {
        font-size: 17.5px;
        bottom: 12.5px;
        right: 15px;
    }

    .download-container:hover .download-btn {
        font-size: 25px;
    }

    .bio-box {
        width: 500px;
        font-size: 15px;
    }

    .copy-btn {
        font-size: 20px;
    }

    .media-split div {
        padding: 0 20px 80px 20px;
        width: 300px;
    }

    .media-split p {
        font-size: 20px;
    }

    .yt-lite > .lty-playbtn {
        width: 42px;
        height: 27.6px;
    }
    
    .yt-lite > .lty-playbtn:before {
        border-width: 6.58px 0 6.58px 11.39px;
    }
}

@media (max-width: 685px) {
    .headshot-split img {
        width: 100px;
        height: 100px;
        padding: 5px;
    }

    .download-headshots-container {
        margin-top: -75px;
        margin-bottom: 80px;
    }

    .sharon-bio {
        margin-bottom: 60px;
    }

    h3 {
        font-size: 20px;
    }


    .download-btn {
        font-size: 8.75px;
        bottom: 6.25px;
        right: 7.5px;
    }

    .download-container:hover .download-btn {
        font-size: 12.5px;
    }

    .bio-box {
        width: 300px;
        font-size: 10px;
    }

    .copy-btn {
        font-size: 15px;
    }

    .media-split div {
        padding: 0 20px 50px 20px;
        width: 200px;
    }

    .media-split p {
        font-size: 15px;
    }

    .yt-lite > .lty-playbtn {
        width: 28px;
        height: 18.4px;
    }
    
    .yt-lite > .lty-playbtn:before {
        border-width: 4.4px 0 4.4px 7px;
    }

    .gen-his {
        margin-top: 0px;
        margin-bottom: -20px;
    }
    
    .gen-his p {
        font-size: 18px;
    }
}

@media (max-width: 485px) {
    .headshot-split img {
        width: 100px;
        height: 100px;
        padding: 5px;
    }

    .popup {
        margin-left: -100.5px; 
        width: 175px;
        font-size: 10px;
      }

    .download-headshots-container {
        margin-top: -75px;
        margin-bottom: 80px;
    }

    .sharon-bio {
        margin-bottom: 60px;
    }

    h3 {
        font-size: 30px;
    }

    .download-btn {
        font-size: 8.75px;
        bottom: 6.25px;
        right: 7.5px;
    }

    .download-container:hover .download-btn {
        font-size: 12.5px;
    }

    .bio-box {
        width: 300px;
        font-size: 10px;
    }

    .copy-btn {
        font-size: 15px;
    }

    .media-split div {
        padding: 0 10px 40px 10px;
        width: 41.25%;
    }

    .lty-playbtn {
        width: 1px;
    }

    .media-split p {
        font-size: 10px;
    }

    .gen-his {
        margin-top: 0px;
        margin-bottom: -20px;
    }
    
    .gen-his p {
        font-size: 12px;
    }
}
/* footer styles */

.footer-container {
    max-width: 1500px;
    margin: auto;
    text-align: center;
    font-family: var(--cormorant-font);
    font-size: 24px;
}

.footer-container img {
    margin-bottom: -160px;
}

.footer-container a {
    color: var(--accent-clr);
    text-decoration: none;
}

@media (max-width: 1325px) {
    .footer-container {
        max-width: 1250px;
        font-size: 20px;
    }
    
    .footer-container img {
        width: 400px;
        margin-bottom: -120px;
    }
}

@media (max-width: 1025px) {
    .footer-container {
        max-width: 950px;
        font-size: 16px;
    }
    
    .footer-container img {
        width: 300px;
        margin-bottom: -100px;
    }
}

@media (max-width: 685px) {
    .footer-container {
        max-width: 585px;
        font-size: 12px;
    }
    
    .footer-container img {
        width: 200px;
        margin-bottom: -60px;
    }
}

@media (max-width: 485px) {
    .footer-container {
        max-width: 385px;
        font-size: 8px;
    }
    
    .footer-container img {
        width: 150px;
        margin-bottom: -40px;
    }
}

/* about styles */
.header-center {
    display: flex;
    justify-content: center;
}

.about-section-sharon {
    max-width: 550px;
    font-family: var(--cormorant-font);
    font-size: 60px;
    font-weight: 100;
    font-style: normal;
    color: var(--main-font-clr-gray);
    position: relative;
    text-align: center;
    margin-bottom: 160px;
    margin-top: -50px;
    font-weight: 400;
}

.about-section-sharon b {
    color: var(--accent-clr);
    font-weight: 400;
}

.header-center p:nth-child(2) {
    color: var(--accent-clr);
    font-size: 40px;
    font-style: italic;
}

.about-section {
    max-width: 1300px;
    margin: auto;
    text-align: center;
    margin-top: 100px;
}

.about-container div  {
    text-align: justify;
}

.sharon-head-shot {
    width: 675px;
    margin-top: -30px;
    margin-bottom: 215px;
}

.about-family-photos {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    margin-top: 25px;
}

.divider {
    margin: auto;
    height: 2.5px;
    width: 100%;
    background: blue;
    background: linear-gradient(90deg, hsl(0, 0%, 100%), var(--accent-clr), hsl(0, 0%, 100%));
}

.stack-photo-back {
    grid-column: 1 / -1;
    grid-row: 1;
    z-index: 0;
}

.stack-photo-back img {
    width: 600px;
}

.stack-photo-mid {
    grid-column: 7 / -2;
    grid-row: 1;
    z-index: 1;
    padding-top: 70%;
}

.stack-photo-mid img {
    width: 600px;
}

.stack-photo-top {
    grid-column: -6 / 3;
    grid-row: 1;
    z-index: 2;
    padding-top: 110%;
}

.stack-photo-top img {
    width: 600px;
}

.stack-photo-text {
    grid-column: -6 / 3;
    grid-row: 1;
    z-index: 2;
    padding-top: 101%;
}

.stack-photo-text {
    font-family: var(--cormorant-font);
    color: var(--main-font-clr-gray);
    font-size: 50px;
}

.psalm {
    width: 1000px;
    margin-top: 50px;
    margin-bottom: -100px;
}

.about-container {
    display: flex;
}

.about-container div {
    width: 800px;
}

.about-container p {
    font-family: var(--cormorant-font);
    font-size: 21px;
    line-height: 1.5;
    padding: 0 20px 0 20px;
}

.about-container p:nth-child(1) {
    margin-top: -90px;
}

@media (max-width: 1375px) {
    .header-center {
        display: flex;
        justify-content: center;
    }
    
    .about-section-sharon {
        max-width: 550px;
        font-size: 46px;
        margin-bottom: 123px;
        margin-top: -50px;
    }
    
    .about-section-sharon b {
        color: var(--accent-clr);
        font-weight: 400;
    }

    .about-section {
        max-width: 1000px;
        margin: auto;
        text-align: center;
        margin-top: 100px;
    }
    
    .about-container div  {
        text-align: justify;
    }
    
    .sharon-head-shot {
        width: 519.2px;
        margin-top: -30px;
        margin-bottom: 215px;
    }
    
    .about-family-photos {
        margin-top: 25px;
    }
    
    .stack-photo-back img {
        width: 461.5px;
    }
    
    .stack-photo-mid img {
        width: 461.5px;
    }
    
    .stack-photo-top img {
        width: 461.5px;
    }
    
    .stack-photo-text {
        font-size: 38.4px;
    }
    
    .psalm {
        width: 769px;
        margin-top: 50px;
        margin-bottom: -100px;
    }
    
    .about-container {
        display: flex;
    }
    
    .about-container div {
        width: 800px;
    }
    
    .about-container p {
        font-family: var(--cormorant-font);
        font-size: 16.1px;
        line-height: 1.5;
    }
    
    .about-container p:nth-child(1) {
        margin-top: -80px;
    }
}

@media (max-width: 1050px) {
    .about-section-sharon {
        max-width: 550px;
        font-size: 46px;
        margin-bottom: 50px;
        margin-top: -50px;
    }

    .about-section {
        max-width: 700px;
    }
    
    .about-container div  {
        margin-bottom: 50px;
    }
    
    .sharon-head-shot {
        display: block;
        margin: auto;
        width: 519.2px;
        margin-top: -30px;
        margin-bottom: 150px;
    }
    
    .about-family-photos {
        margin-top: 25px;
    }
    
    .stack-photo-back img {
        width: 323px;
    }
    
    .stack-photo-mid img {
        width: 323px;
    }
    
    .stack-photo-top img {
        width: 323px;
    }
    
    .stack-photo-text {
        font-size: 26.87px;
    }
    
    .psalm {
        width: 538.3px;
        margin-top: 0px;
        margin-bottom: -100px;
    }
    
    .about-container {
        flex-direction: column;
    }
    
    .about-container div {
        width: 700px;
    }
    
    .about-container p {
        font-size: 16.1px;
    }
}

@media (max-width: 725px) {
    .about-section-sharon {
        max-width: 550px;
        font-size: 32.85px;
        margin-bottom: 0px;
        margin-top: -60px;
    }

    .about-section {
        max-width: 500px;
        margin: auto;
        text-align: center;
        margin-top: 100px;
    }
    
    .about-container div  {
        margin: auto;
        text-align: justify;
        margin-bottom: 50px;
    }
    
    .sharon-head-shot {
        display: block;
        margin: auto;
        width: 370px;
        margin-top: -30px;
        margin-bottom: 150px;
    }
    
    .about-family-photos {
        margin-top: 25px;
    }
    
    .stack-photo-back img {
        width: 230.7px;
    }
    
    .stack-photo-mid img {
        width: 230.7px;
    }
    
    .stack-photo-top img {
        width: 230.7px;
    }
    
    .stack-photo-text {
        font-size: 19.17px;
    }
    
    .psalm {
        width: 384.45px;
        margin-top: 0px;
        margin-bottom: -100px;
    }
    
    .about-container {
        flex-direction: column;
    }
    
    .about-container div {
        width: 500px;
    }
    
    .about-container p {
        font-family: var(--cormorant-font);
        font-size: 13px;
        line-height: 1.5;
    }
    
    .about-container p:nth-child(1) {
        margin-top: -80px;
    }
}

@media (max-width: 525px) {
    .about-section-sharon {
        max-width: 550px;
        font-size: 32.85px;
        margin-top: -100px;
    }

    .about-section {
        max-width: 350px;
        margin: auto;
        text-align: center;
        margin-top: 100px;
    }
    
    .about-container div  {
        margin: auto;
        text-align: justify;
        margin-bottom: 50px;
    }
    
    .sharon-head-shot {
        width: 259px;
        margin-top: -80px;
        margin-bottom: 100px;
    }
    
    .about-family-photos {
        margin-top: 25px;
    }
    
    .stack-photo-back img {
        width: 161.5px;
    }
    
    .stack-photo-mid img {
        width: 161.5px;
    }
    
    .stack-photo-top img {
        width: 161.5px;
    }
    
    .stack-photo-text {
        font-size: 13.4px;
    }
    
    .psalm {
        width: 350px;
        margin-top: 0px;
        margin-bottom: -75px;
    }
    
    .about-container {
        flex-direction: column;
    }
    
    .about-container div {
        width: 350px;
    }
    
    .about-container p {
        font-family: var(--cormorant-font);
        font-size: 13px;
        line-height: 1.5;
    }
    
    .about-container p:nth-child(1) {
        margin-top: -80px;
    }
}

/* faq page  */

.genesis-picture-container {
    text-align: center;
}

.genesis-picture-container img {
    margin-top: -70px;
    margin-bottom: -10px;
    width: 900px;
}

.faq-container {
    max-width: 1300px;
    margin: auto;
}

#faq-title-container p {
    font-size: 39px;
    font-family: var(--cormorant-font);
    font-weight: 400;
    width: 1450px;
}

#faq-title-container {
    width: 1300px;
    margin: auto;
    margin: 0 25px 0 25px;
}

.accordian {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    width: 1250px;
    margin: auto;
    margin: 0 25px 0 25px;
}

.faq-item {
    border-bottom: 1px dashed #CEE1F8;
    padding: 2px 20px;
}

.faq-item:nth-child(3) {
    border: none;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: white;
    z-index: 500;
    font-family: var(--cormorant-font);
    font-size: 20px;
    color: var(--accent-clr);
    cursor: pointer;
}

.faq-title-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: white;
    z-index: 500;
    font-family: var(--cormorant-font);
    font-size: 20px;
    color: var(--accent-clr);
    cursor: pointer;
}

.plus-minus {
    color: var(--accent-clr);
    font-size: 30px;
    font-weight: 700;
    font-family: var(--main-font);
}

.faq-content-hide {
    position: relative;
    z-index: 0;
    max-height: 0;
    padding: 5px 5px 5px 15px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
    font-family: var(--cormorant-font);
    font-size: 25px;
    color: var(--main-font-clr-gray);
}

.faq-content-show {
    overflow: hidden;
    position: relative;
    z-index: 0;
    height: auto;
    max-height: 9999px;
    border-radius: 10px;
    padding: 5px 5px 5px 15px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
    font-family: var(--cormorant-font);
    font-size: 25px;
    color: var(--main-font-clr-gray);
}

.statement-of-faith {
    margin: 0 25px 0 25px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    padding: 60px;
}

.statement-title {
    margin: 0 25px 30px 25px;
    font-size: 42px;
    font-family: var(--cormorant-font);
    font-weight: 400;
    margin-top: 100px;
}

.statement-of-faith h2:nth-child(1) {
    margin-top: 0;
}

.statement-of-faith h2 {
    font-size: 30px;
    font-family: var(--cormorant-font);
    font-weight: 700;
    margin-top: 100px;
    color: var(--accent-clr);
}

.statement-of-faith p {
    font-size: 23px;
    font-family: var(--cormorant-font);
    font-weight: 400;
    color: var(--main-font-clr-gray);
    padding-left: 20px;
}

.statement-of-faith b {
    font-weight: 500;
    color: black;
}

@media only screen and (max-width: 1350px) {
    .genesis-picture-container img {
        width: 100%;
        max-width: 900px;
        margin-top: -67px;
        margin-bottom: -10px;
    }
    
    .faq-container {
        max-width: 100%;
        margin: auto;
        padding: 0 15px;
        margin: 0 30px 0 20px 
    }
    
    #faq-title-container p {
        width: 100%;
        font-size: 30px;
    }
    
    #faq-title-container {
        width: 100%;
        margin: 0;
    }
    
    .accordian {
        width: 100%;
        margin: 0;
    }
    
    .faq-item {
        padding: 2px 10px;
    }
    
    .faq-title,
    .faq-title-selected {
        font-size: 18px;
    }
    
    .plus-minus {
        font-size: 25px;
    }
    
    .faq-content-hide,
    .faq-content-show {
        font-size: 20px;
    }
    
    .statement-of-faith {
        margin: 0;
        padding: 30px;
    }
    
    .statement-title {
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 30px;
        margin-left: 0px;
    }
    
    .statement-of-faith h2 {
        font-size: 25px;
        margin-top: 50px;
    }
    
    .statement-of-faith p {
        font-size: 20px;
        padding-left: 15px;
    }
}

@media (max-width: 550px) {
    .faq-container {
       margin-top: -70px;
    }

    .faq-title {
        font-size: 15px;
    }
}

/* contact styling */

.contact-container {
    max-width: 1500px;
    margin: auto;
}

.contact-container b { 
    color: var(--accent-clr);
    font-weight: 400;
}

.contact-img-container {
    text-align: center;
}

.contact-container img {
    width: 900px;
    margin-top: -125px;
    margin-bottom: -50px;
}

label {
    text-transform: uppercase;
    font-family: var(--cormorant-font);
    font-size: 18px;
}

form { max-width:500px; margin:50px auto; }

.form-input {
    color:var(--main-font-clr-gray);
    font-family: var(--cormorant-font);
    font-weight: 500;
    font-size: 17px;
    border-radius: 5px;
    line-height: 22px;
    background-color: transparent;
    border: 3px solid #99dbe5;
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
}

.form-input:focus { border:3px solid var(--accent-clr); }

textarea {
    height: 150px;
    line-height: 150%;
    resize:vertical;
}

[type="submit"] {
    font-family: var(--cormorant-font);
    width: 100%;
    background:#99dbe5;
    border-radius:5px;
    border:0;
    cursor:pointer;
    color:white;
    font-size:28px;
    padding-top:10px;
    padding-bottom:10px;
    transition: all 0.3s;
    margin-top:-4px;
    font-weight:700;
}

[type="submit"]:hover { background:var(--accent-clr); }
@media (max-width: 1000px ) {
    .contact-container img {
        width: 100%;
        /* margin-bottom: -75px; */
    }
}
@media (max-width: 727px) {
    .contact-container {
        max-width: 100%;
        margin: -50px auto auto auto;
        padding: 0 20px;

    }

    .contact-container img {
        width: 100%;
        margin-top: -225px;
        margin-bottom: -50px;
    }

    form { max-width:70%; margin: auto; }

    .form-input {
        font-size: 16px;
        padding: 12px;
        margin-bottom: 10px;
    }

    textarea { height: 120px; }

    [type="submit"] {
        font-size: 24px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-top: -3px;
    }
}

@media (max-width: 805px) {
    label {
        font-size: 16px;
    }
}

@media (max-width: 724px) {
    label {
        font-size: 14px;
    }

    .contact-container img {
        margin-top: -80px;
        margin-bottom: -15px;
    }
}

.page-not-found {
    text-align: center;
    font-size: 30px;
    font-family: var(--cormorant-font);
    color: var(--main-font-clr-gray)
}

.page-not-found a {
    text-decoration: none;
    color: var(--accent-clr);
}

@media (max-width: 700px) {
    .page-not-found {
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    .page-not-found {
        font-size: 15px;
    }
}

